import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GitHub, Twitter, Mail, Linkedin, Instagram } from "react-feather"

import * as S from "../styles"
import AvatarImage from "../images/avatar.jpeg"

const IndexPage = () => (
  <Layout>
    <SEO title="Piyush Daryani" />
    <S.IndexPage>
      <S.Container>
        <S.Avatar src={AvatarImage} />
        <S.About>
          <S.Greeting> Hi, I'm Piyush </S.Greeting>
          <S.Introduction>
            I am in business of helping yours. Summing it up for you. Mapping
            your success. Providing clean bill of Financial health.
          </S.Introduction>
          <S.Contact>
            <S.ContactLink href="https://twitter.com/Piyush_Daryani">
              <Twitter />
            </S.ContactLink>
            <S.ContactLink href="https://www.linkedin.com/in/piyush-daryani/">
              <Linkedin />
            </S.ContactLink>
            <S.ContactLink href="https://www.instagram.com/piyushdaryani.jpg/">
              <Instagram />
            </S.ContactLink>
            <S.ContactLink href="mailto:piyushdaryani@gmail.com?subject=Hello!">
              <Mail />
            </S.ContactLink>
          </S.Contact>
        </S.About>
      </S.Container>
    </S.IndexPage>
  </Layout>
)

export default IndexPage
