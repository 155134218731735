import styled from "styled-components"

export const IndexPage = styled.section({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

export const Container = styled.div({
  maxWidth: "45rem",
  margin: "0px 20px 0px 20px",
  display: "flex",
  alignItems: "center",
  "@media only screen and (max-width: 768px)": {
    flexDirection: "column",
  },
})

export const Avatar = styled.img({
  width: 200,
  height: 200,
  borderRadius: "100%",
})
export const About = styled.div({
  "@media only screen and (max-width: 768px)": {
    marginTop: 20,
    alignItems: "center",
  },
  marginLeft: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  maxWidth: 350,
})

export const Greeting = styled.div(
  {
    fontSize: "3em",
    fontFamily: "'PT Serif', serif",
    marginBottom: 10,
  },
  ({ theme }) => ({
    color: theme.colors.secondary,
  })
)
export const Introduction = styled.div({
  "@media only screen and (max-width: 768px)": {
    textAlign: "center",
  },
  fontSize: "1.3em",
  lineHeight: 1.3,
  marginBottom: 15,
})

export const Contact = styled.div({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  "@media only screen and (max-width: 768px)": {
    justifyContent: "center",
  },
})

export const ContactLink = styled.a.attrs({
  target: "_blank",
})({
  color: "inherit",
  marginLeft: 8,
  marginRight: 8,
})
